.error-boundary-container {
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.error-boundary-container .tips {
  margin-top: 40px;
  font-size: 20px;
}

.error-boundary-container .auto-tips {
  margin-top: 20px;
  font-size: 14px;
  opacity: 0.5;
}

.error-boundary-container .icon {
  margin-top: 100px;
}
